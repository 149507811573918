import React from "react"
import { Link, graphql } from 'gatsby'
import LayoutAlt from "../components/layout-alt"
import styled from 'styled-components';
import Img from 'gatsby-image';
import scrollTo from 'gatsby-plugin-smoothscroll';


const SplitBlock = styled.div`
  display:grid;
  &.right{
    grid-template-columns:2fr 1fr;
  }
  &.left{
    grid-template-columns:1fr 2fr;
  }
  @media(max-width:1450px){
    display:block;
    .describer{
      width:75%;
      margin: 0 auto;
      @media(max-width:700px){
        width:90%;
      }
    }
  }
  &.wide{
    grid-template-columns:1fr;
    .wide-text{
      width: 50%;
      margin: 0 auto;
      @media(max-width:1450px){
        width:75%;
        padding:0 50px;
        box-sizing:border-box;
      }
      @media(max-width:700px){
        width:90%;
      }
    }
  }
  .image-grid{
    display:grid;
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
    img{
      width:100%;
    }
  }
  .header{ 
    font-size: 35px;
    padding: 50px 50px 0px 50px;
    @media(max-width:400px){
      font-size:26px;
      padding: 25px 25px 0px 25px;
      margin-bottom:0;
    }
    &.left{
      grid-column:1;
      grid-row: 1;
    }
    &.right{
      grid-column:2;
      grid-row: 1;
    }
  }
  .describer{
    grid-row:2;
    
  }
  .text-block{
    padding:0 0 0 50px;
    padding-right:50px;
    @media(max-width:1450px){
      margin-bottom: 100px;
    }
    @media(max-width:400px){
      margin-bottom:25px;
    }
    @media(max-width:400px){
      padding:20px;
      h1{
        font-size:18px;
        margin-top:0;
      }
      h2{
        font-size:16px;
      }
      h3{
        font-size:14px;
      }
      h4{
        font-size:12px;
      }
      h5{
        font-size:10px;
      }
      h6{
        font-size:8px;
      }
      p, li{
        font-size:12px;
        line-height:22px;
      }
    }
    p{
      line-height: 28px;
    }
    ul, ol{
      line-height: 30px;
      li{
        padding-bottom:10px;
      }
    }
    a{
    padding: 5px;
    border-radius: 20px;
    -webkit-transition: ease all 0.5s;
    transition: ease all 0.5s;
      &:hover{
        transition: ease all 0.5s;
      }
    }
    &.left{
      grid-column:1;
      grid-row: 2;
    }
    &.right{
      grid-column:2;
      grid-row: 2;
    }
  }
  .image-block{
    &.right{
      grid-column:2;
      grid-row: 2;
    }
    &.left{
      grid-column:1;
      grid-row: 2;
    }
  }
`

const NewBlock = styled.section`
  box-sizing:border-box;
  margin:85px auto 85px auto; 
  //border:1px solid aqua;
  width:1440px;
  @media(max-width:1440px){
    width:100%;
  }
  .wide-text-model{
    width: 50%;
    margin: 0 auto;
  }
  h1{
    font-size:35px;
    
  }
  .model{
    text-align:center;
  }
  @media(max-width:1450px){
    margin-bottom:15px;
  }
  a{
    color: ${({ color }) => color};
    font-weight:bold;
  }
`

const HeroSection = styled.section`
  .gatsby-image-wrapper{
    width:100%;
    height:100vh;
    @media(max-width:1250px){
      height:initial;
    }
  }
  img{
    width:100%;
    
  }
`

const Intro = styled.section`
  padding:25px 50px;
  box-sizing:border-box;
  display:grid;
  width:1440px;
  margin: 0 auto;
  //border: 1px solid #ffffff;
  grid-template-columns:1fr 25px 25px 1fr;
  //justify-items:self-end;
  @media(max-width:1440px){
    width:100%;
  }
  @media(max-width:750px){
    display:block;
  }
  .intro-text{
    //width:90%;
    text-align:left;
    //border:1px solid aqua;
    box-sizing:border-box;
    &:first-child{
      //padding-right:10%;
    }
    &:last-child{
      //padding-left:10%;
    }
  }
  h1{
    color:#ffffff;
    font-weight:400;
    text-transform: uppercase;
    font-size:16px;
    @media(max-width:400px){
      font-size:12px;
    }
  }
  p{
    font-size: 20px;
    line-height: 32px;
    @media(max-width:400px){
      font-size:16px;
      line-height: 28px;
    }
  }
  .line{
    height:100%;
    width:1px;
    //border-right:1px solid #ffffff;
    @media(max-width:750px){
      border-top:1px solid #ffffff;
      width:100%;
      height:1px;
      border-right:none;
      margin-bottom:40px;
      margin-top:30px;
    }
  }
  .lineB{
    border-left:1px solid #ffffff;
  }
  .collab{
    display:grid;
    //width:90%;
    grid-template-columns:125px 1fr;
    //align-items: baseline;
      h2, p, a{
      text-align:left;
      color:#ffffff;
    }
    a, p{
      font-size:12px;
    }
  }
`

const IntroWrapper = styled.div`
background: ${({ color }) => color}; 
`

const BlockGridWrapper = styled.div`
  display:grid;
  width:1440px;
  margin:0 auto;
  //border: 1px solid aqua;
  grid-template-columns:repeat(auto-fit, minmax(300px, 1fr));
  justify-items:center;
  grid-column-gap: 20px;
  padding:100px 0;
  @media(max-width:1440px){
    width:100%;
  }
`

const BlockGrid = styled.div`
  /*background:aqua;*/
  width:100%;
`

const CenterMe = styled.section`
  margin: 0 auto;
  padding:0 25px;
`

const BlockGridTitle = styled.h1`
  font-size: 35px;
  padding: 50px 50px 0px 50px;
  text-align:center;
`

const BlockQuote = styled.blockquote`
    quotes: "“" "”"; /* one pair */
    text-align:center;
    font-size:40px;
    width: 50%;
    margin: 0 auto;
    padding: 100px 0;
    @media(max-width:1000px){
      font-size:26px;
      line-height:32px;
    }
    @media(max-width:700px){
      width:75%;
    }
    &:before{
      content: open-quote;
      font-size:60px;
      @media(max-width:1000px){
        font-size:40px;
      }
    }
    &:after{
      content: close-quote;
      font-size:60px;
      @media(max-width:1000px){
        font-size:40px;
      }
    }
`

const SplitBlockQuote = styled.blockquote`
    quotes: "“" "”"; /* one pair */
    text-align:center;
    font-size:40px;
    grid-row: 3;
    grid-column-start: 1;
    grid-column-end: 3;
    width: 50%;
    margin: 0 auto;
    padding: 100px 0 0 0;
    @media(max-width:1000px){
      font-size:26px;
      line-height:32px;
    }
    @media(max-width:700px){
      width:75%;
    }
    &:before{
      content: open-quote;
      font-size:60px;
      @media(max-width:1000px){
        font-size:40px;
      }
    }
    &:after{
      content: close-quote;
      font-size:60px;
      @media(max-width:1000px){
        font-size:40px;
      }
    }
`

// const Collab = styled.div`
//     display:grid;
//     grid-template-columns:200px 1fr;
//     width:1200px;
//     align-items:left;
//     margin: 0 auto;
//     padding:0 0 25px 0;
//     h2, p, a{
//       text-align:left;
//       color:#ffffff;
//     }
// `

const ProjectsLink = styled.h3`
  font-size:30px;
  text-align:center;
  padding-bottom:50px;
  a{
    color:#ffffff;
    border-radius:20px;
    padding:10px;
    background:lightgrey;
    &:hover{
      background:blue;
    }
  }
`

const Arrow = styled.div`
    position:absolute;
    z-index:100;
    bottom:100px;
    left:50%;
    width:50px;
    margin-left:-25px;
    cursor:pointer;
    animation: MoveUpDown 1s ease infinite;
    @media(max-width:1250px){
        animation: MoveUpDownMob 1s ease infinite;
        width:30px;
        margin-left:-15px;
        display:none;
    }
`

const Turn = styled.div` 
    img{
      width:50px;
      margin:0 auto;
      display:block;
      animation: turn 2s ease infinite;
    }
`

const ArrowBottom = styled.div`
   margin:0 auto;
   transform:rotate(180deg);
   width:50px;
   padding:50px;
   cursor:pointer;
   img{
     width:50px;
   }
`

const HeroWrap = styled.section`
  position:relative;
`

const ProjectsList = styled.ul`
  text-align:center;
  padding:0;
  li{
    display:inline-block;
    padding:0 15px;
    a{
      &[aria-current="page"] {
        color:#000000;

      }
      color:#8e8e8e;
      text-decoration:none;
    }
  }
  .allProjects{
    text-decoration:underline;
  }
`

const Iframe = styled.div`
  max-width: 1279px; max-height: 854px;
  margin:0 auto;
  div{
    left: left: 0px; width: 100%; height: 0px; position: relative; padding-bottom: 66.7709%; overflow: hidden;
    iframe{
      position: absolute; top: 0px; left: 0px; height: 100%; width: 1px; min-width: 100%; *width: 100%;
    }
  }
`

const IndexPage = ({data}) => {

  const wrap = data => {
    const res = [[data[0]]];
    let curr = data[0];
    // let curr = 0;
    let idx = 0;
    for (let i = 1; i < data.length; i++) {
      if (data[i].__typename === curr.__typename) {
        res[idx].push(data[i]);
      } else {
        curr = data[i];
        idx += 1;
        res[idx] = [curr];
      }
    }
    return res
  };

  const wrappedData = wrap(data.datoCmsProject.projectBlock);

  console.log(wrappedData + "test");


  return(
  <LayoutAlt>
    
    {/* <h1>{data.datoCmsProject.titleOfProject}</h1> */}

    <HeroSection id="top">
      {data.datoCmsProject.heroImage ? 
      <HeroWrap>
          <Img fluid={data.datoCmsProject.heroImage.fluid} />
          <Arrow onClick={() => scrollTo('#some-id')}>
            <img src={data.arrow.publicURL} alt="" />
          </Arrow>

          </HeroWrap> 
        
      : 
        null
      }
      {data.datoCmsProject.heroIntroBackgroundColor ? 
      <>
        <IntroWrapper id="some-id" color={data.datoCmsProject.heroIntroBackgroundColor.hex}>
            <Intro>
            <div className="intro-text">
              <h1>{data.datoCmsProject.projectHeaderLeftText}</h1>
              <p>{data.datoCmsProject.projectPargraphLeft}</p>
            </div>
            <div className="line"/>
            <div className="lineB"/>
            <div className="intro-text">
              <h1>{data.datoCmsProject.projectHeaderRightText}</h1>
              <p>{data.datoCmsProject.projectParagraphRight}</p>
            </div>
            {data.datoCmsProject.collaborators &&
              <div className="collab">
              <h2>
                Colloborators :
              </h2>
                <div dangerouslySetInnerHTML={{ __html:data.datoCmsProject.collaborators}}/>
              </div>
            }
          </Intro>
          
          
        </IntroWrapper>
        
        </>
      :
        null 
      }
        
    </HeroSection>

    {/* {      console.log(data.datoCmsProject.projectBlock[0].__typename)
} */}

    {
    
    
      wrappedData.map(list => {

        // console.log(list[0].__typename);

        if(list[0] !== undefined){
        
        let isNewBlock = list[0].__typename === "DatoCmsSingleProjectBlockContent"

        if (isNewBlock){ return (    
          <>
            {list.map(projectEntry => (
                <NewBlock color={data.datoCmsProject.heroIntroBackgroundColor.hex} key={projectEntry.id}> 
                {/* <SplitBlock className={projectEntry.wideView ? 'wide' : 'narrow'}> */}
                {projectEntry.wideView ? 
                
                  <SplitBlock className="wide">
                    {projectEntry.titleOfSection &&<h1 className={`header ` + (projectEntry.imageTextSide ? 'right' : 'left')}>{projectEntry.titleOfSection}</h1> }
      
                   
                    {projectEntry.imageAssetHideShow ? 
                      <div className="image-grid">
                      {projectEntry.imageAsset.map(imageEntry => {
                        return <img key={imageEntry.originalId} src={imageEntry.url}/>
                      })} 
                      </div> 
                    :
                      null
                    }

                      {projectEntry.calloutQuote &&
                        <BlockQuote>
                          {projectEntry.calloutQuote}
                        </BlockQuote>
                      } 

                     {projectEntry.descriptionToggle ? 
                      <>
                      <div className="wide-text"
                      dangerouslySetInnerHTML={{ __html: projectEntry.descriptionOfImage}}
                      />
                      </>
                    :
                      null
                    }




                      
                    

                  </SplitBlock> 
      
                :
      
                  <SplitBlock className={projectEntry.imageTextSide ? 'right' : 'left'}>
                      <div className="describer">
                      {projectEntry.titleOfSection && <h1 className={`header ` + (projectEntry.imageTextSide ? 'right' : 'left')}>{projectEntry.titleOfSection}</h1> }
                      {projectEntry.descriptionToggle &&
                        <div className={`text-block ` + (projectEntry.imageTextSide ? 'right' : 'left')}
                        dangerouslySetInnerHTML={{ __html: projectEntry.descriptionOfImage}}
                        />
                      }
                      </div>
                   
                    {projectEntry.imageAssetHideShow ? 
                    <div className={`image-grid image-block ` + (!projectEntry.imageTextSide ? 'right' : 'left')}>
                      {projectEntry.imageAsset.map(imageEntry => {
                        return <img key={imageEntry.originalId} src={imageEntry.url}/>
                      })}        
                    </div>  
                    :
                      null
                    }

                    {projectEntry.calloutQuote &&
                        <SplitBlockQuote>
                          {projectEntry.calloutQuote}
                        </SplitBlockQuote>
                      } 

                  </SplitBlock> 
      
                
                }
                   
      
              </NewBlock>
              )
            )
            }
          </>
        );
        }else if(list[0].__typename === "DatoCmsProjectBlockGridTitle"){
          return (    
            <>
              {list.map(l => (
                  <>
                  <BlockGridTitle>{l.blockGridTitle}</BlockGridTitle>
                  </>
                )
              )
              }
            </>
          );
        }else if(list[0].__typename === "DatoCmsProjectBlockGrid"){
          return (   
            <CenterMe>
              <BlockGridWrapper color={data.datoCmsProject.heroIntroBackgroundColor.hex}>
                {list.map(projectEntry => (
                    <>
                      <BlockGrid key={projectEntry.id}>  
                          <h1>{projectEntry.titleOfGridSection}</h1>
                          {projectEntry.imageOfGridBlock && !!projectEntry.showImage &&
                            <Img fluid={projectEntry.imageOfGridBlock.fluid} /> 
                          }
                          {!!projectEntry.showDescription &&
                            <div 
                            dangerouslySetInnerHTML={{ __html: projectEntry.singleBlockDescription}}
                            />
                          }
                      </BlockGrid>
                    </>
                  )
                )
                }
              </BlockGridWrapper>
            </CenterMe> 
          );
        }else if(list[0].__typename === "DatoCms3dModel"){
          return ( 
            <NewBlock>
            {list.map(modelEntry => (
                <section key={modelEntry.id}>


                    <h1 className="header model">{modelEntry.title}</h1>
                
                    <Turn>
                      <img src={data.turn.publicURL} alt="" />
                    </Turn>
                    {modelEntry.keyshotUrl && 
                    
                    <Iframe >
                      <div >
                        <iframe src={modelEntry.keyshotUrl} frameBorder="0" scrolling="no" allowFullScreen="allowfullscreen"></iframe>
                      </div>
                    </Iframe>
                      
                    }

                    <div className="wide-text-model"
                    dangerouslySetInnerHTML={{ __html: modelEntry.descriptionOfModel}}/>


                </section>
              )
            )
          }
          </NewBlock>
            
          );
        }
        // the rest - notice different 'fieldset'
      }

      })
    

  

    
    }

    
   

    <ProjectsList>
      {data.allDatoCmsProject.edges.map(({ node: product }) => ( 
        <li  key={product.id}>
          
            <Link to= {`/projects/${product.slug}`}> 
            {product.titleOfProject}
            </Link>
        
          
        </li>
      ))}
      <li className="allProjects"><Link to="/">All Projects</Link></li>
    </ProjectsList>

    
    {/* <ProjectsLink>
      <Link to="/">All Projects</Link>
    </ProjectsLink> */}

    <ArrowBottom onClick={() => scrollTo('#top')}>
            <img src={data.arrow.publicURL} alt="up arrow" />
    </ArrowBottom>
     
    
    
  </LayoutAlt>
)

}

export default IndexPage

export const query = graphql`
  query ProjectQuery($slug: String!) {
    arrow: file(relativePath: {eq: "arrow.svg"}) {
      publicURL
    }
    turn: file(relativePath: {eq: "turn.svg"}) {
      publicURL
    }
    datoCmsProject(slug: { eq: $slug }){
      titleOfProject
      projectHeaderLeftText
      projectHeaderRightText
      projectParagraphRight
      projectPargraphLeft
      collaborators
      heroIntroBackgroundColor {
        hex
      }
      heroImage {
        fluid(maxWidth:1800) {
          ...GatsbyDatoCmsFluid
        }
      }
      projectBlock{
        __typename
        ... on DatoCmsSingleProjectBlockContent {
            id
            titleOfSection
            descriptionOfImage
            descriptionToggle
            calloutQuote
            wideView
            imageTextSide
            imageAssetHideShow
            imageAsset{
              url
              originalId
            }
          }
        __typename
        ... on DatoCmsProjectBlockGrid {
          id
          titleOfGridSection
          singleBlockDescription
          showImage
          showDescription
          imageOfGridBlock {
              fluid(maxWidth:1200) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        __typename
        ... on DatoCmsProjectBlockGridTitle {
          id
          blockGridTitle
        }
        __typename
        ... on DatoCms3dModel {
          id
          keyshotUrl
          title
          descriptionOfModel
        }
      }   
    }
    allDatoCmsProject{
      edges{
        node{
          id
          titleOfProject
          slug
        }
      }
    }
  }
`